<template>
  <div class="home">
    <header>
      <div class="container">
        <router-link to="/" class="logo">
          <img src="../assets/suburban-negativo.png" alt="" class="" />
        </router-link>

        <div class="col">
          <nav :class="menuIsOpen ? 'open' : ''">
            <a href="#quem-somos" @click="menuIsOpen = false">Quem somos</a>
            <a href="#sustentabilidade" @click="menuIsOpen = false"
              >Sustentabilidade</a
            >
            <a href="#clientes" @click="menuIsOpen = false">Clientes</a>
            <a href="#certificacoes" @click="menuIsOpen = false"
              >Certificações</a
            >
            <a href="#contato" @click="menuIsOpen = false">Contato</a>
          </nav>

          <button
            class="menu-hamburger"
            :class="menuIsOpen ? 'open' : ''"
            @click="menuIsOpen = !menuIsOpen"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </header>

    <div class="banner">
      <div class="container">
        <p class="title">Grupo Suburban</p>
      </div>

      <div class="container">
        <p>Compromisso com a excelência nos impulsiona</p>
      </div>

      <div class="container">
        <a href="#quem-somos" class="cta">
          <p>Saiba mais</p>
        </a>
      </div>
    </div>

    <section class="quem-somos" id="quem-somos">
      <div class="container">
        <p class="title">Quem Somos</p>

        <p>
          Bem-vindo ao <strong>Grupo Suburban</strong> - Uma Indústria
          <strong>100% Brasileira!</strong><br />
          Temos o prazer de nos apresentar como Suburban, uma renomada
          <strong>indústria têxtil</strong> especializada na produção de roupas
          de alta qualidade para varejistas de destaque
          <strong>em todo o Brasil</strong>. Além disso, temos muito orgulho de
          nossa coleção de marcas próprias exclusivas.
        </p>

        <p>
          Com um forte foco na
          <strong>moda, qualidade, responsabilidade social e inovação,</strong>
          estamos comprometidos em fornecer produtos excepcionais que atendam às
          demandas em constante evolução do mercado.
        </p>

        <img class="d77-desk d77-img" src="../assets/d77.png" alt="" />

        <div class="d77-mobile">
          <carousel
            :autoplay="true"
            :paginationEnabled="true"
            :perPage="1"
          >
            <slide>
              <img class="d77-img" src="../assets/d77-1.png" alt="" />
            </slide>
            <slide>
              <img class="d77-img" src="../assets/d77-2.png" alt="" />
            </slide>
            <slide>
              <img class="d77-img" src="../assets/d77-3.png" alt="" />
            </slide>
            <slide>
              <img class="d77-img" src="../assets/d77-4.png" alt="" />
            </slide>
          </carousel>
        </div>
      </div>
    </section>

    <section class="sustentabilidade no-bg first" id="sustentabilidade">
      <div class="container">
        <p class="subtitle">HÁ <strong>47 ANOS</strong> MARCANDO HISTÓRIAS</p>

        <p>
          Como uma empresa comprometida com a responsabilidade social e a
          sustentabilidade, buscamos constantemente inovação em nossos processos
          de produção. <strong>Valorizamos a ética</strong> em nosso negócio,
          garantindo condições justas de trabalho e respeito aos direitos
          humanos.
        </p>

        <img class="photos" src="../assets/photos.png" alt="" />
      </div>
    </section>

    <section class="empresas no-bg" id="empresas">
      <div class="container">
        <p class="title">Empresas do grupo</p>

        <div class="row">
          <div class="col">
            <a
              href="https://www.instagram.com/outletbeachtennis/"
              target="_blank"
            >
              <img src="../assets/outlet-beach-tennis.png" alt="" />
              <img src="../assets/outlet-beach-tennis-img.png" alt="" />
            </a>
          </div>

          <div class="col">
            <a
              href="https://www.instagram.com/wundersportsoficial/"
              target="_blank"
            >
              <img src="../assets/wunder.png" alt="" />
              <img src="../assets/wunder-img.png" alt="" />
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="certificados no-bg" id="certificacoes">
      <div class="container">
        <p class="title">Certificados</p>

        <div class="row">
          <carousel
            :autoplay="true"
            :paginationEnabled="true"
            :perPageCustom="[
              [425, 1],
              [768, 2],
              [1024, 3],
            ]"
          >
            <slide>
              <a href="#" class="hover-me">
                <img class="abvtex" src="../assets/selo-abvtex.png" alt="" />
                <span>
                  Selo ABVTEX: Nosso compromisso com a qualidade e a
                  responsabilidade social é evidenciado pelo Selo ABVTEX, que
                  atesta o cumprimento de rigorosos padrões éticos e de
                  segurança em todas as etapas de produção de nossos produtos
                  têxteis. Garantimos transparência e confiança aos nossos
                  clientes, assegurando que cada peça seja fabricada de maneira
                  ética e sustentável.
                </span>
              </a>
            </slide>
            <slide>
              <a href="#" class="hover-me">
                <img class="disney" src="../assets/disney-fama.png" alt="" />
                <span>
                  Disney FAMA (Facility and Merchandise Authorization): Estamos
                  orgulhosos de possuir a certificação Disney FAMA, que
                  reconhece a excelência em qualidade e segurança em nossos
                  produtos licenciados. Isso demonstra nosso compromisso em
                  oferecer produtos seguros e de alta qualidade para
                  consumidores de todas as idades, de acordo com os padrões
                  rigorosos da Disney.
                </span>
              </a>
            </slide>
            <slide>
              <a href="#" class="hover-me">
                <img class="cotton" src="../assets/better-cotton.png" alt="" />
                <span
                  >Better Cotton Initiative (BCI): Como membro da Better Cotton
                  Initiative, estamos comprometidos com a produção responsável
                  de algodão. Nosso apoio a essa iniciativa global reflete nosso
                  compromisso em promover práticas agrícolas sustentáveis,
                  reduzindo o impacto ambiental e melhorando as condições de
                  trabalho dos agricultores de algodão em todo o mundo.</span
                >
              </a>
            </slide>
          </carousel>
        </div>
      </div>
    </section>

    <section class="clientes no-bg" id="clientes">
      <div class="container">
        <p class="title">Clientes</p>

        <div class="row desk">
          <div class="col">
            <div>
              <img class="besni" src="../assets/besni.png" alt="" />
            </div>
          </div>

          <div class="col">
            <div>
              <img class="cea" src="../assets/cea.png" alt="" />
            </div>
          </div>

          <div class="col">
            <div>
              <img class="centauro" src="../assets/centauro.png" alt="" />
            </div>
          </div>

          <div class="col">
            <div>
              <img class="decathlon" src="../assets/decathlon.png" alt="" />
            </div>
          </div>

          <div class="col">
            <div>
              <img class="digaspi" src="../assets/digaspi.png" alt="" />
            </div>
          </div>

          <div class="col">
            <div>
              <img class="renner" src="../assets/renner.png" alt="" />
            </div>
          </div>

          <div class="col">
            <div>
              <img class="rchlo" src="../assets/rchlo.png" alt="" />
            </div>
          </div>

          <div class="col">
            <div>
              <img class="torra" src="../assets/torra.png" alt="" />
            </div>
          </div>
        </div>

        <div class="row mobile">
          <carousel
            :autoplay="true"
            :paginationEnabled="true"
            :perPageCustom="[
              [425, 1],
              [768, 2],
            ]"
          >
            <slide>
              <div>
                <img class="besni" src="../assets/besni.png" alt="" />
              </div>
            </slide>

            <slide>
              <div>
                <img class="cea" src="../assets/cea.png" alt="" />
              </div>
            </slide>

            <slide>
              <div>
                <img class="centauro" src="../assets/centauro.png" alt="" />
              </div>
            </slide>

            <slide>
              <div>
                <img class="decathlon" src="../assets/decathlon.png" alt="" />
              </div>
            </slide>

            <slide>
              <div>
                <img class="digaspi" src="../assets/digaspi.png" alt="" />
              </div>
            </slide>

            <slide>
              <div>
                <img class="renner" src="../assets/renner.png" alt="" />
              </div>
            </slide>

            <slide>
              <div>
                <img class="rchlo" src="../assets/rchlo.png" alt="" />
              </div>
            </slide>

            <slide>
              <div>
                <img class="torra" src="../assets/torra.png" alt="" />
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </section>

    <section class="contato no-bg" id="contato">
      <div class="container">
        <div class="row">
          <div class="col">
            <p>
              Acreditamos que a moda é uma
              <strong>forma de expressão e empoderamento</strong>, e é por isso
              que nos esforçamos para oferecer produtos que atendam às
              expectativas de nossos clientes.
            </p>

            <p>
              Nossa expertise na indústria têxtil nos permite criar coleções
              exclusivas, sempre com foco na qualidade e nas tendências mais
              recentes. No Grupo Suburban, cada peça é produzida cuidadosamente,
              com <strong>atenção a cada detalhe</strong>. Nosso compromisso com
              a excelência nos impulsiona
              <strong>a superar as expectativas do B2B e B2C</strong>.
            </p>
          </div>

          <div class="col">
            <form>
              <p>Quer falar conosco? Preencha!</p>

              <div class="fields">
                <input
                  type="text"
                  id="name"
                  name="name"
                  class="p"
                  placeholder="Nome"
                  v-model="name"
                />
                <input
                  type="email"
                  id="email"
                  name="email"
                  class="m"
                  placeholder="E-Mail"
                  v-model="email"
                />
                <input
                  type="tel"
                  id="cellphone"
                  name="cellphone"
                  class="p"
                  placeholder="Celular"
                  v-model="cellPhone"
                  v-mask="'(##) #-####-####'"
                />
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  class="m"
                  placeholder="Assunto"
                  v-model="subject"
                  v-mask="'(##) #-####-####'"
                />
                <textarea name="" id="" cols="30" rows="10" class="g" placeholder="Mensagem" v-model="message"></textarea>
              </div>

              <button
                @click.prevent="sendContactForm()"
                :style="formIsSubmitting ? 'pointer-events: none;' : ''"
              >
                <span v-if="formIsSubmitting">
                  <div class="loader"></div>
                </span>
                <span v-else> Enviar </span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="newsletter no-bg" id="newsletter">
      <div class="container">
        <div class="row">
          <div class="col">
            <p class="title">JUNTE-SE A NÓS</p>

            <p class="subtitle">OBTENHA DESCONTOS EXCLUSIVOS</p>
          </div>

          <div class="col">
            <form>
              <div class="fields">
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Seu nome"
                  v-model="nlName"
                />
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Digite seu e-mail"
                  v-model="nlEmail"
                />
              </div>

              <button @click.prevent="sendNewsletter()">Assinar</button>
            </form>
          </div>
        </div>
      </div>
    </section> -->

    <section class="toast-wrapper" :class="toastIsActive ? 'active' : ''">
      <div class="container">
        <div class="toast">
          <span v-if="contactFormErrors.length != 0" class="msg-wrapper">
            <span v-for="(error, key) in contactFormErrors" :key="key">
              {{ error }}
            </span>
          </span>
          <span v-if="newsletterFormErrors.length != 0" class="msg-wrapper">
            <span v-for="(error, key) in newsletterFormErrors" :key="key">
              {{ error }}
            </span>
          </span>
        </div>
      </div>
    </section>

    <footer>
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="block">
              <p class="title">Contato</p>
              <p>(11) 2790-1800</p>
              <p>contato@suburban.com.br</p>
            </div>
            <div class="block">
              <p class="title">Endereço</p>
              <p>Rua Hipódromo, 331 - Brás, São Paulo - SP, 03051-000</p>
            </div>
          </div>
          <div class="col">
            <p class="title">Redes sociais</p>
            <div class="redes">
              <a
                href="https://www.instagram.com/grupo.suburban/"
                target="_blank"
              >
                <img src="../assets/instagram.png" alt="" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100095551687290"
                target="_blank"
              >
                <img src="../assets/facebook.png" alt="" />
              </a>
              <a
                href="https://br.linkedin.com/company/grupo-suburban"
                target="_blank"
              >
                <img src="../assets/linkedin.png" alt="" />
              </a>
            </div>
          </div>
          <div class="col">
            <img src="../assets/suburban.png" alt="" />
          </div>
        </div>
        <div class="row">
          <p>Todos direitos reservados à Grupo Suburban - Sao Paulo - SP</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "HomeView",

  components: {},

  beforeCreate() {},

  async beforeMount() {
    this.isPageLoading = true;
  },

  async mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.isPageLoading = false;
  },

  async beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  data() {
    return {
      isPageLoading: true,
      isScroll: false,
      menuIsOpen: false,

      senderMail: "contato@suburban.com.br",

      name: "",
      email: "",
      phone: "",
      cellPhone: "",
      subject: "",
      message: "",
      formIsSubmitting: false,

      nlName: "",
      nlEmail: "",
      nlIsSubmitting: false,

      toastIsActive: false,
      contactFormErrors: {},
      newsletterFormErrors: {},
      emailError: "",
    };
  },

  watch: {},

  computed: {},

  methods: {
    handleScroll() {
      this.menuIsOpen = false;
      if (window.scrollY >= 50) {
        this.isScroll = true;
      } else {
        this.isScroll = false;
      }
    },

    validateEmail(email) {
      if (email) {
        const re = /\S+@\S+\.\S+/;
        if (!re.test(email)) return false;
        return true;
      }
    },

    sendContactForm() {
      this.formIsSubmitting = true;
      this.toastIsActive = false;
      this.contactFormErrors = {};
      this.newsletterFormErrors = {};

      if (!this.name) this.contactFormErrors.name = "Por favor, insira seu primeiro nome.";
      if (!this.email) this.contactFormErrors.email = "Por favor, insira seu email.";
      if (!this.validateEmail(this.email)) this.contactFormErrors.email = "Por favor, verifique o campo e-mail.";
      if (!this.cellPhone) this.contactFormErrors.cellPhone = "Por favor, insira seu celular.";
      if (!this.subject) this.contactFormErrors.subject = "Por favor, insira o assunto.";
      if (!this.message) this.contactFormErrors.message = "Por favor, escreva sua mensagem.";

      if (Object.keys(this.contactFormErrors).length === 0) {
        var msg = `
          ${this.name} ${this.lastName} | ${this.email}
          Empresa: ${this.enterprise} / CNPJ: ${this.cnpj}
          Telefone: ${this.phone}
          Celular: ${this.cellPhone}
        `;

        axios.defaults.headers.post["Content-Type"] = "application/json";
        axios
          .post(`https://formsubmit.co/ajax/${this.senderMail}`, {
            name: `${this.name} ${this.lastName}`,
            message: msg,
          })
          .then((response) => {
            console.log(response);
            if (response.data.success) {
              this.contactFormErrors.ok =
                "Enviado! Em breve um de nossos representantes entrará em contato com você.";

              this.formIsSubmitting = false;
              this.toastIsActive = true;

              var time = Object.keys(this.contactFormErrors).length * 5000;

              setTimeout(() => {
                this.toastIsActive = false;
              }, time);
            }
            this.formIsSubmitting = false;
          })
          .catch((error) => {
            console.log(error);
            this.contactFormErrors.error =
              "Ocorreu um erro ao enviar sua mensagem. Por favor, tente novamente mais tarde.";
            this.formIsSubmitting = false;
          });
      } else {
        this.formIsSubmitting = false;
        this.toastIsActive = true;

        var time = Object.keys(this.contactFormErrors).length * 2000;

        setTimeout(() => {
          this.toastIsActive = false;
        }, time);
      }
    },

    // sendNewsletter() {
    //   this.nlIsSubmitting = true;
    //   this.toastIsActive = false;
    //   this.contactFormErrors = {};
    //   this.newsletterFormErrors = {};

    //   if (!this.nlName) this.newsletterFormErrors.nlName = "Por favor, insira seu nome.";
    //   if (!this.nlEmail) this.newsletterFormErrors.nlEmail = "Por favor, insira seu email.";
    //   if (!this.validateEmail(this.nlEmail)) this.newsletterFormErrors.nlEmail = "Por favor, verifique o campo e-mail.";

    //   if (Object.keys(this.newsletterFormErrors).length === 0) {
    //     var msg = `
    //       ${this.nlEmail} ${this.nlName}
    //     `;

    //     axios.defaults.headers.post["Content-Type"] = "application/json";
    //     axios
    //       .post(`https://formsubmit.co/ajax/${this.senderMail}`, {
    //         name: this.newsletterFormErrors.nlName,
    //         message: msg,
    //       })
    //       .then((response) => {
    //         console.log(response);
    //         if (response.data.success) {
    //           this.contactFormErrors.ok =
    //             "Você foi cadastrado com sucesso em nosso Newsletter!";

    //           this.formIsSubmitting = false;
    //           this.toastIsActive = true;

    //           var time = Object.keys(this.contactFormErrors).length * 5000;

    //           setTimeout(() => {
    //             this.toastIsActive = false;
    //           }, time);
    //         }
    //         this.formIsSubmitting = false;
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         this.contactFormErrors.error =
    //           "Ocorreu um erro ao cadastrar no NewsLetter. Por favor, tente novamente mais tarde.";
    //         this.formIsSubmitting = false;
    //       });
    //   } else {
    //     this.nlIsSubmitting = false;
    //     this.toastIsActive = true;

    //     var time = Object.keys(this.newsletterFormErrors).length * 2000;

    //     setTimeout(() => {
    //       this.toastIsActive = false;
    //     }, time);
    //   }
    // },
  },
};
</script>

<style scoped lang="scss">
.home {
  position: relative;
  z-index: 0;
  background-image: url("@/assets/white-bg.png");

  .banner {
    position: relative;
    margin-top: -170px;

    background: #0f0f0f;

    background-image: url("@/assets/banner-bg.jpg");
    background-position: top right;
    background-repeat: no-repeat;

    padding-top: 330px;
    padding-bottom: 210px;

    @media only screen and (max-width: 425px) {
      background-size: cover;
    }

    .container {
      @media only screen and (max-width: 425px) {
        justify-content: center;
        text-align: center;
      }

      p {
        max-width: 640px;
        color: #ffffff;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;

        margin-top: 40px;

        text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.2);

        &.title {
          max-width: 100%;
          font-size: 80px;
          font-weight: 600;

          margin-top: 0;

          @media only screen and (max-width: 1024px) {
            font-size: 55px;
            line-height: 55px;
          }

          @media only screen and (max-width: 425px) {
            font-size: 30px;
            line-height: 30px;
            text-align: center;
          }

          span {
            color: #ff5e29;
          }
        }
      }

      .cta {
        text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.2);
        border-radius: 60px;
        border: 2px solid #ffffff;

        padding: 15px 40px;
        margin-top: 40px;

        p {
          margin: 0;
          font-size: 30px;
          font-weight: 300;
          line-height: 30px;

          @media only screen and (max-width: 425px) {
            text-align: center;
          }
        }

        &:hover {
          background: #000000;
        }
      }
    }
  }

  section {
    &.no-bg {
      background: #ffffff;

      &.first {
        background: linear-gradient(
          360deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 80%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }

    &.quem-somos {
      padding-top: 85px;

      p {
        &.title {
          margin-bottom: 110px;
        }
      }

      .d77-desk {
        display: block;

        @media only screen and (max-width: 768px) {
          display: none;
        }
      }

      .d77-mobile {
        display: none;
        margin-bottom: 40px;
        
        @media only screen and (max-width: 768px) {
          display: block;
        }
      }

      .d77-img {
        margin-top: 140px;
        margin-bottom: 140px;
        width: 100%;

        @media only screen and (max-width: 768px) {
          margin-top: 10px;
          margin-bottom: 10px;
          margin: 0 25px;
        }
      }
    }

    &.sustentabilidade {
      p {
        &.subtitle {
          margin-bottom: 80px;
        }
      }

      .photos {
        margin-top: 140px;
        margin-bottom: 140px;
        width: 100%;
      }
    }

    &.empresas {
      p {
        &.title {
          margin-bottom: 90px;
        }
      }

      .row {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        @media only screen and (max-width: 425px) {
          flex-wrap: wrap;
        }

        .col {
          margin: 0 15px;

          &:first-child {
            @media only screen and (max-width: 425px) {
              margin-bottom: 30px;
            }
          }

          a {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            transition: all 1s ease;

            &:hover {
              transform: scale(1.05);
            }

            img {
              &:first-child {
                width: 100%;
                max-width: 220px;
                margin-bottom: 30px;

                @media only screen and (max-width: 425px) {
                  width: 100%;
                  max-width: 150px;
                }
              }
              &:last-child {
                width: 100%;
              }
            }
          }
        }
      }
    }

    &.certificados {
      padding-top: 155px;

      .row {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        &.title {
          margin-bottom: 130px;

          @media only screen and (max-width: 425px) {
            margin-bottom: 30px;
          }
        }
      }

      @media only screen and (max-width: 768px) {
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          transform: scale(1.05);
        }

        img {
          width: 100%;
          transform: scale(0.95);
        }
      }

      ::v-deep .VueCarousel-wrapper {
        @media only screen and (min-width: 768px) {
          overflow: unset;
        }
      }

      .abvtex {
        max-width: 247px;
      }
      .disney {
        max-width: 468px;
      }
      .cotton {
        max-width: 370px;
      }
      .b {
        max-width: 143px;
      }
    }

    &.clientes {
      padding-top: 155px;

      p {
        &.title {
          margin-bottom: 130px;

          @media only screen and (max-width: 425px) {
            margin-bottom: 30px;
          }
        }
      }

      .row {
        justify-content: space-between;
        flex-wrap: wrap;

        @media only screen and (max-width: 768px) {
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
        }

        &.desk {
          display: flex;

          @media only screen and (max-width: 768px) {
            display: none;
          }
        }

        &.mobile {
          display: none;

          @media only screen and (max-width: 768px) {
            display: unset;
          }

          a {
            margin: 15px;
          }

          img {
            width: 100%;
          }
        }

        .col {
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease;

            &:hover {
              transform: scale(1.05);
            }

            img {
              width: 100%;
              transform: scale(0.95);
            }
          }
        }
      }
    }

    &.contato {
      padding-top: 240px;
      padding-bottom: 240px;

      @media only screen and (max-width: 425px) {
        padding-top: 120px;
        padding-bottom: 120px;
      }

      .row {
        align-items: flex-start;

        @media only screen and (max-width: 768px) {
          flex-wrap: wrap;
          width: 100%;
        }

        .col {
          width: 100%;
          max-width: 800px;

          @media only screen and (max-width: 1024px) {
            width: 50%;
            max-width: 50%;
          }

          @media only screen and (max-width: 768px) {
            width: 100%;
            max-width: 100%;
          }

          &:last-child {
            border: 2px solid #000000;
            padding: 40px;
            width: 680px;
            margin-left: 50px;

            @media only screen and (max-width: 1024px) {
              width: 50%;
              max-width: 50%;
              margin-left: 10px;
            }

            @media only screen and (max-width: 768px) {
              width: 100%;
              max-width: 100%;
              margin-left: 0;
            }
          }

          p {
            text-align: left;
            max-width: 790px;
            margin-bottom: 30px;

            @media only screen and (max-width: 425px) {
              text-align: center;
            }
          }

          form {
            p {
              text-align: center;
            }

            .fields {
              display: flex;
              flex-wrap: wrap;

              input[type="text"],
              input[type="email"],
              input[type="tel"],
              textarea {
                width: 100%;
                padding: 13px 10px;
                margin: 5px;

                border-radius: 5px;
                background: #f1f1f1;
                border: 0;

                font-size: 16px;

                &.p {
                  width: calc(100% / 5 * 2 - 10px);
                }
                &.m {
                  width: calc(100% / 5 * 3 - 10px);
                }
                &.g {
                  width: calc(100% - 10px);
                }
              }

              button {
                display: block;
                width: 100%;
                padding: 10px;
                background-color: #007bff;
                color: #fff;
                border: none;
                border-radius: 5px;
                cursor: pointer;
              }

              button:hover {
                background-color: #0056b3;
              }
            }

            button {
              display: table;
              margin: auto;
              margin-top: 85px;

              background: #000000;
              border-radius: 10px;
              padding: 10px 50px;
              border: 2px solid #000000;

              color: #ffffff;
              font-size: 22px;
              font-weight: 600;
              text-transform: uppercase;

              &:hover {
                background: transparent;
                color: #000;
              }
            }
          }
        }
      }
    }

    &.newsletter {
      background: #eeeeee;
      padding: 45px 0;

      .container {
        .row {
          @media only screen and (max-width: 768px) {
            flex-wrap: wrap;
          }

          .col {
            @media only screen and (max-width: 768px) {
              width: 100%;
              max-width: 100%;
            }

            &:first-child {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: flex-start;

              @media only screen and (max-width: 768px) {
                justify-content: center;
              }

              p {
                font-size: 16px;
                line-height: 16px;
                text-align: left;

                @media only screen and (max-width: 768px) {
                  text-align: center;
                  margin-bottom: 15px;
                }

                &.title {
                  font-weight: 800;
                  font-size: 20px;
                  line-height: 20px;

                  @media only screen and (max-width: 768px) {
                    margin-bottom: 0;
                  }
                }
              }
            }

            &:last-child {
              display: flex;
              align-items: center;
              justify-content: flex-end;

              @media only screen and (max-width: 768px) {
                justify-content: center;
              }

              form {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                @media only screen and (max-width: 425px) {
                  flex-wrap: wrap;
                }

                .fields {
                  display: flex;
                  flex-wrap: wrap;

                  input[type="text"],
                  input[type="email"],
                  input[type="tel"] {
                    padding: 13px 10px;
                    margin: 5px;

                    background: #ffffff;
                    border: 0;

                    font-size: 14px;

                    @media only screen and (max-width: 425px) {
                      width: 100%;
                    }
                  }
                }

                button {
                  display: table;
                  margin-left: 10px;

                  background: #333;
                  border: 2px solid #333;
                  padding: 12px 30px;

                  color: #ffffff;
                  font-size: 14px;
                  font-weight: 300;
                  text-transform: uppercase;

                  @media only screen and (max-width: 425px) {
                    margin: auto;
                  }

                  &:hover {
                    background: transparent;
                    color: #000;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.toast-wrapper {
      position: fixed;
      z-index: 10;
      bottom: -100%;
      right: 0;
      transition: all 0.3s ease;

      &.active {
        bottom: 0;
      }

      .container {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
      }

      .toast {
        background: rgba($color: #000000, $alpha: 1);
        padding: 15px;

        .msg-wrapper {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;

          span {
            color: #fff;

            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;

            text-align: çeft;
            padding: 5px 0;
          }
        }
      }
    }
  }

  footer {
    //background: #ffffff;
    background: #eeeeee;

    .container {
      display: flex;
      flex-direction: column;

      .row {
        &:first-child {
          padding: 55px 0;

          @media only screen and (max-width: 768px) {
            flex-wrap: wrap;
          }
        }

        &:last-child {
          border-top: 1px solid #dddddd;
          padding: 25px 0;

          @media only screen and (max-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .col {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          &:first-child,
          &:nth-child(2) {
            @media only screen and (max-width: 768px) {
              width: 100%;
              margin-bottom: 50px;
              align-items: center;
              justify-content: center;
            }
          }

          &:last-child {
            @media only screen and (max-width: 768px) {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            img {
              @media only screen and (max-width: 425px) {
                width: 100%;
              }
            }
          }

          .redes {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            @media only screen and (max-width: 768px) {
              align-items: center;
              justify-content: center;
            }

            a {
              margin-right: 10px;

              @media only screen and (max-width: 425px) {
                text-align: center;
              }

              &:hover {
                opacity: 0.5;
              }
            }
          }

          .block {
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            @media only screen and (max-width: 768px) {
              align-items: center;
              justify-content: center;
            }

            &:last-child {
              margin-bottom: 0;

              a {
                @media only screen and (max-width: 425px) {
                  text-align: center;
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }

          a {
            font-size: 16px;
            margin-bottom: 15px;
            color: #000000;
            text-decoration: underline;

            &:hover {
              color: #909090;
            }
          }

          p {
            font-size: 16px;
            margin-bottom: 15px;

            &.title {
              font-size: 18px;
              font-weight: 700;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }

  ::v-deep .VueCarousel-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

*, *:before, *:after {
  // border: 10px dashed red;
}
</style>