/*************************************************************************************************************
 * Autor   : Danilo Targas
 * Data    : 12/12/2023
**************************************************************************************************************/

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import './main.scss'

import VueMask from 'v-mask'
Vue.use(VueMask)

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
