<!-- 
 * Autor   : Danilo Targas
 * Data    : 03/04/2024
-->

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "App",

  components: {},

  beforeCreate() {},

  async beforeMount() {},

  async mounted() {
    this.isPageLoading = false;
  },

  async beforeDestroy() {},

  data() {
    return {
      isPageLoading: true,
    };
  },

  watch: {},

  computed: {},

  methods: {},
};
</script>

<style scoped lang="scss">
#app {}
</style>
